@import 'reset.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'hamburgers.css';
@import 'common.scss';
@import 'header.scss';
@import 'overlayMenu.scss';
@import 'popUp.scss';
@import 'business.scss';
//page color var
$hybridColor: #00ffeb;
$onlineLiveColor: #00ffeb;
$vStudioColor: #0062ff;
$designColor: #00ff44;
$originalIpColor: #ffff00;
$fmLabColor: #ff4929;
$itSolutionColor: #0062ff;
@import 'businessNoscrolling.scss';
@import 'businessScrolling.scss';
@import 'headSection.scss';
@import 'portfolio.scss';
@import 'people.scss';
@import 'contact.scss';

.pageTransition {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  @import 'admin.scss';
}

.App {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.diagramWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
  .diagram {
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    flex: 0 0 auto;
    .diagramContentWrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      .diagramNumber {
        font-weight: 900;
      }
      .diagramTitle {
        font-weight: 900;
      }
      .diagramContent {
        font-weight: 500;
      }
    }
  }
}

.noscolling,
.scrolling {
  .description {
    line-height: 1.75;
  }
  .content {
    line-height: 1.75;
  }
}

.logIn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  button {
    background: linear-gradient(90deg, rgba(0, 185, 239, 1) 0%, rgba(255, 69, 210, 1) 100%);
    padding: 12px 56px;
    border-radius: 36px;
    font-size: 24px;
    font-weight: 900;
    color: #fff;
  }
}

.notfoundWrapper {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .notfound {
    width: 40%;
    height: auto;
    @media screen and (max-width: 640px) {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  button {
    display: block;
    margin: 48px auto 0;
    font-size: 24px;
    font-weight: 900;
    padding: 8px 48px;
    color: #fff;
    border-radius: 36px;
    background-color: #000;
  }
}
