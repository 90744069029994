.headSection {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 180px;
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  position: relative;
  .headTitle {
    font-size: 116px;
    font-weight: 900;
    width: fit-content;
    line-height: 1.15 !important;
    opacity: 0;
  }
  .subTitle {
    font-size: 50px;
    font-weight: 700;
    margin-top: 36px;
    width: fit-content;
    opacity: 0;
    &.small {
      font-size: 30px;
    }
  }
  .content {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    width: fit-content;
    margin-left: auto;
    line-height: 1.5;
    opacity: 0;
    &.small {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 1280px) and (min-width: 641px) {
    padding-top: 56px;
    transform: scale(0.7);
    .content {
      margin-top: -48px;
    }
  }
  @media screen and(max-width: 640px) {
    max-width: none;
    width: calc(100% - 40px);
    padding-top: 124px;
    box-sizing: border-box;
    display: block;
    .headTitle {
      font-size: 50px;
    }
    .subTitle {
      font-size: 24px;
      margin-top: 72px;
    }
    .content {
      margin-left: 0;
      margin-top: 30px;
      font-size: 24px;
      word-break: keep-all;

      &.small {
        font-size: 14px;
      }
    }
  }
}

.hybridWrapper,
.vStudioWrapper,
.originalIpWrapper,
.itSolutionWrapper {
  .headSection {
    .content {
      position: absolute;
      top: 580px;
      right: 0;
      @media screen and(max-width: 640px) {
        position: relative;
        top: 0;
        font-size: 20px;
      }
    }
  }
}

.onlineLiveWrapper,
.fmLabWrapper {
  .headSection {
    .content {
      position: absolute;
      top: 620px;
      right: 0;
      @media screen and(max-width: 640px) {
        position: relative;
        top: 0;
        font-size: 20px;
      }
    }
  }
}

.hybridWrapper {
  color: $hybridColor;
  .headSection {
    .content {
    }
  }
}

.onlineLiveWrapper {
  color: $onlineLiveColor;
  .headSection {
    .content {
      @media screen and(max-width: 640px) {
        padding: 0;
        br {
          display: none;
        }
      }
    }
  }
}

.vStudioWrapper {
  color: $vStudioColor;
  .headSection {
    .headTitle {
    }
    .subTitle {
    }
    .content {
      padding: 120px 0;
    }
    @media screen and(max-width: 640px) {
      .headTitle {
        font-size: 46px;
      }
      .content {
        font-size: 14px;
        padding: 0;
        br {
          display: none;
        }
      }
    }
  }
}

.designWrapper {
  color: $designColor;
  .headSection {
    .headTitle {
    }
    .subTitle {
      font-weight: 900;
      span {
        font-weight: 700;
        font-size: 36px;
      }
    }
    .content {
      span {
        &.unique {
          color: $designColor;
        }
        &.small {
          font-size: 20px;
        }
      }
    }
    @media screen and(max-width: 640px) {
      .subTitle {
        span {
          font-size: 20px;
        }
      }
      .content {
        font-size: 14px;
        line-height: 1.75;
        span {
          display: block;
          line-height: 1.75;
          &.unique:nth-of-type(2) {
            margin-top: 30px;
          }
          &.small {
            font-size: 12px;
            margin-top: 10px;
            &:last-of-type {
              margin-top: 10px;
            }
          }
        }
        br {
          display: none;
        }
      }
    }
  }
}

.originalIpWrapper {
  color: $originalIpColor;
  .headSection {
    .headTitle {
    }
    .subTitle {
    }
    .content {
    }
  }
}

.itSolutionWrapper {
  color: $itSolutionColor;
  .headSection {
    .content {
      color: #000;
      padding: 48px 0;
      @media screen and(max-width: 640px) {
        padding: 0;
        font-size: 14px;
      }
    }
  }
}

.fmLabWrapper {
  color: $fmLabColor;
  .headSection {
    .content {
      color: #000;
      @media screen and(max-width: 640px) {
        font-size: 14px;
        padding: 0;
        position: relative;
        span {
        }
        br {
          &:nth-of-type(2),
          &:nth-of-type(4) {
            display: none;
          }
        }
      }
      span {
        color: $fmLabColor;
      }
    }
  }
}
