.noscolling {
  &.designWrapper,
  &.originalIpWrapper,
  &.hybridWrapper {
    .contentSection {
      color: #ffffff;
    }
  }
  &.fmLabWrapper {
    .contentSection {
      color: #000;
    }
  }
  .contentSection {
    max-width: 1280px;
    margin: calc(var(--vh, 1vh) * 18) auto 0;
    position: relative;
    .switchingPage {
      .pageSubMenu {
        position: sticky;
        top: 148px;
        z-index: 10;
        ul {
          display: flex;
          justify-content: flex-end;
          li {
            font-weight: 900;
            font-size: 18px;
            cursor: pointer;
            &:nth-of-type(n + 2) {
              margin-left: 48px;
            }
          }
        }
      }
      .contentWrapper {
        height: calc(var(--vh, 1vh) * 100);
        padding-top: calc(var(--vh, 1vh) * 22);
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        .descriptionWrapper {
          opacity: 0;
          .title {
            font-size: 60px;
            font-weight: 900;
          }
          .content {
            margin-top: 48px;
            line-height: 1.75;
          }
        }
      }
    }
    @media screen and(max-width: 1280px) and (min-width: 641px) {
      .switchingPage {
        .pageSubMenu {
          transform: scale(0.7);
          top: 72px;
        }
        .contentWrapper {
          transform: scale(0.7);
          padding-top: calc(var(--vh, 1vh) * 12);
          .descriptionWrapper {
            .content {
              letter-spacing: -1.2px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 640px) {
      width: calc(100% - 40px);
      margin: auto;
      .switchingPage {
        .pageSubMenu {
          top: 64px;
          ul {
            justify-content: flex-start;
            flex-wrap: wrap;
            li {
              font-size: 10px;
              width: 33.3%;
              padding-bottom: 8px;
              &:nth-of-type(n + 2) {
                margin-left: 0;
              }
            }
          }
        }
        .contentWrapper {
          height: auto; // to be adjust
          padding-top: 132px;
          flex-direction: column;
          justify-content: flex-start;
          .descriptionWrapper {
            .title {
              font-size: 30px;
            }
            .content {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
}

//no scrolling common

.hybridWrapper {
  .switchingPage {
    .pageSubMenu ul li {
      &.focused {
        color: $hybridColor;
      }
    }
    .contentWrapper {
      .descriptionWrapper {
        .title {
          color: $hybridColor;
        }
        .content {
          font-size: 14px;
          font-weight: 700;
        }
        .footer {
          font-size: 12px;
          font-weight: 500;
          a {
            text-decoration: none;
            font-size: 22px;
            font-weight: 900;
            color: inherit;
            display: inline-block;
            margin-top: 64px;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #fff;
            }
          }
        }
      }
      .refImgWrapper {
        width: 750px;
        opacity: 0;
        .main {
          width: 100%;
          height: 420px;
          cursor: pointer;
          outline: none;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mainAction {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(59, 9, 0, 0.5);
            transition: all 0.2s ease;
            &:hover {
              background-color: rgba(59, 9, 0, 0);
            }
            .contentWrapper {
              position: relative;
              width: 100%;
              height: 100%;
              padding: 30px;
              box-sizing: border-box;
              display: flex;
              justify-content: space-between;
              .title {
                font-size: 24px;
                font-weight: 900;
              }
              span {
                display: block;
                margin-top: auto;
                margin-left: auto;
                width: 25px;
                height: 25px;
              }
            }
          }
        }
        .imgsWrapper {
          margin-top: 20px;
          .slick-slider {
            .slick-prev {
              display: none;
              left: 8px;
              z-index: 10;
              width: auto;
              height: auto;
              &:before {
                content: '';
                background-image: url('./img/prev.svg');
                background-size: cover;
                width: 15px;
                height: 30px;
                display: block;
              }
            }
            .slick-next {
              display: none;
              right: 8px;
              width: auto;
              height: auto;
              &:before {
                content: '';
                background-image: url('./img/next.svg');
                background-size: cover;
                width: 15px;
                height: 30px;
                display: block;
              }
            }
            .slick-dots {
              bottom: -28px;
              height: auto;
              li button::before {
                opacity: 0.5;
                color: #fff;
              }
              li.slick-active button::before {
                opacity: 1;
                color: #fff;
              }
            }
          }
          .slick {
            padding-top: 150px;
            position: relative;
            cursor: pointer;
            outline: none;
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1280px) and (min-width: 641px) {
    .switchingPage {
      .contentWrapper {
        .refImgWrapper {
          .main {
            .mainAction {
              .contentWrapper {
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .switchingPage {
      .contentWrapper {
        .descriptionWrapper {
          .content {
            font-size: 12px;
          }
          .footer {
            margin-top: 15px;
            font-size: 8px;
            a {
              margin-top: 24px;
              font-size: 15px;
            }
          }
        }
        .refImgWrapper {
          padding-top: 24px;
          width: 100vw;
          margin-left: -20px;
          .main {
            height: 315px;
            .mainAction {
              .contentWrapper {
                .title {
                  font-size: 20px;
                }
                span {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
          .imgsWrapper {
            margin-top: 15px;
            padding-bottom: 72px;
            .slick-slider {
              .slick-prev {
                left: 4px;
                &:before {
                  width: 10px;
                  height: 20px;
                }
              }
              .slick-next {
                right: 4px;
                &:before {
                  width: 10px;
                  height: 20px;
                }
              }
              .slick-dots {
              }
            }
            .slick {
              padding-top: 100%;
            }
          }
        }
      }
    }
  }
}

.fmLabWrapper {
  .switchingPage {
    .pageSubMenu ul li {
      &.focused {
        color: $fmLabColor;
      }
    }
    .contentWrapper {
      .descriptionWrapper {
        .title {
          color: $fmLabColor;
        }
        .content {
          font-size: 14px;
          font-weight: 700;
        }
      }
      .refImgWrapper {
        padding-top: 36px;
        .rowWrapper {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          text-align: center;
          opacity: 0;
          .title {
            font-size: 20px;
            font-weight: 900;
          }
          .detail {
            font-size: 14px;
            line-height: 1.75;
          }
          .function,
          .link {
            width: 200px;
            padding: 0 24px;
          }
          .function {
            letter-spacing: -0.5px;
            .pictogram {
              width: 180px;
              margin: 0 auto;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .title {
              margin-bottom: 30px;
            }
            .detail {
              font-weight: 700;
            }
          }
          .link {
            .title {
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                display: block;
                margin-left: 8px;
                width: 16px;
                height: 16px;
                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
            .detail {
              font-weight: 500;
            }
          }
          &.circles {
            padding: 56px 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .switchingPage {
      .contentWrapper {
        .descriptionWrapper {
          .content {
            font-size: 12px;
          }
        }
        .refImgWrapper {
          padding: 72px 0 96px 0;
          .rowWrapper {
            flex-wrap: wrap;
            .title {
              font-size: 14px;
            }
            .detail {
              font-size: 12px;
            }
            .function {
              width: auto;
              &:nth-of-type(3) {
                margin-top: 40px;
              }
              .pictogram {
                width: 90px;
              }
              .title {
                margin-bottom: 15px;
              }
            }
            .link {
              width: 100%;
              &:nth-of-type(n + 2) {
                margin-top: 60px;
              }
              .title {
                margin-bottom: 10px;
                span {
                  width: 14px;
                  height: 14px;
                }
              }
            }
          }
        }
      }
      div[name='untact_tech'] {
        .contentWrapper {
          .refImgWrapper {
            .rowWrapper {
              .function {
                width: 140px;
                padding: 0 10px;
              }
            }
          }
        }
      }
    }
  }
}

.designWrapper {
  .switchingPage {
    .pageSubMenu ul li {
      &.focused {
        color: $designColor;
      }
    }
    [name='content5'] .contentWrapper .refImgWrapper.triple {
      justify-content: flex-end;
      cursor: default;
    }
    .contentWrapper {
      .descriptionWrapper {
        .title {
          color: $designColor;
        }
        .content {
          font-size: 30px;
          font-weight: 700;
          &.footer {
            font-size: 18px;
            font-weight: 500;
            margin-top: 148px;
          }
        }
      }
      .refImgWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        opacity: 0;
        .designRefImg {
          position: relative;
          background-size: 102%;
          background-position: center center;
          background-repeat: no-repeat;
          transition: all 0.2s ease;
          &:hover {
            background-size: 120%;
            &::after {
              opacity: 0;
            }
            &::before {
              opacity: 1;
            }
          }
          &::before,
          &::after {
            position: absolute;
            box-sizing: border-box;
            font-weight: 900;
            width: 100%;
            height: 100%;
            padding: 30px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            text-align: center;
            word-break: keep-all;
            top: 0;
            left: 0;
          }
          &::before {
            opacity: 0;
            font-size: 18px;
            letter-spacing: -1px;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
          }
          &::after {
            font-size: 20px;
            background-image: linear-gradient(
              180deg,
              rgba(0, 185, 239, 0.5) 0%,
              rgba(255, 69, 210, 0.5) 100%
            );
          }
        }
        &.double {
          width: 660px;
          flex-wrap: wrap;
          .designRefImg {
            flex: 0 0 auto;
            width: 320px;
            padding-top: 600px;
            &:nth-of-type(n + 2) {
              margin-left: 20px;
            }
            &.appDesign {
              background-image: url('./img/app_design.jpg');
              &::before {
                content: 'App Design';
              }
              &::after {
                content: '앱 디자인';
              }
            }
            &.webDesign {
              background-image: url('./img/web_design.jpg');
              &::before {
                content: 'Web Design';
              }
              &::after {
                content: '웹 디자인';
              }
            }
          }
        }
        &.triple {
          cursor: e-resize;
          width: 700px;
          overflow-x: auto;
          .designRefImg {
            flex: 0 0 auto;
            width: 200px;
            padding-top: 510px;
            &:nth-of-type(n + 2) {
              margin-left: 16px;
            }
            &.branding {
              background-image: url('./img/branding.jpg');
              &::before {
                content: '브랜드 디자인';
              }
              &::after {
                content: 'Branding';
              }
            }
            &.ui_ux {
              background-image: url('./img/ui_ux.jpg');
              &::before {
                content: '사용자 경험 디자인';
              }
              &::after {
                content: 'UI/UX';
              }
            }
            &.character {
              background-image: url('./img/characters.jpg');
              &::before {
                content: '캐릭터/일러스트 디자인';
              }
              &::after {
                content: 'Chracter';
              }
            }
            &.goods {
              background-image: url('./img/goods.jpg');
              &::before {
                content: '굿즈/프로덕트 디자인';
              }
              &::after {
                content: 'goods';
              }
            }
            &.onlineContents {
              background-image: url('./img/online_contents.jpg');
              &::before {
                content: '온라인 컨텐츠';
              }
              &::after {
                content: 'Online Contents';
              }
            }
            &.logo {
              background-image: url('./img/logo.jpg');
              &::before {
                content: '심볼/로고 디자인';
              }
              &::after {
                content: 'Logo';
              }
            }
            &.pattern {
              background-image: url('./img/pattern.jpg');
              &::before {
                content: '패턴 디자인';
              }
              &::after {
                content: 'Pattern';
              }
            }
            &.infographic {
              background-image: url('./img/infographic.jpg');
              &::before {
                content: '인포/아이콘 디자인';
              }
              &::after {
                content: 'Infographic';
              }
            }
            &.typo {
              background-image: url('./img/typo.jpg');
              &::before {
                content: '타이포그라피';
              }
              &::after {
                content: 'Typography';
              }
            }
            &.app {
              background-image: url('./img/app.jpg');
              &::before {
                content: '각종 응용디자인';
              }
              &::after {
                content: 'Application';
              }
            }
            &.snsContentsDesign {
              background-image: url('./img/sns.jpg');
              &::before {
                content: 'SNS 콘텐츠디자인';
              }
              &::after {
                content: 'SNS Contents';
              }
            }
            &.motionGraphics {
              background-image: url('./img/motion.jpg');
              &::before {
                content: '모션 그래픽\A/영상 디자인';
              }
              &::after {
                content: 'Motion Graphic';
              }
            }
            &.marketingContents {
              background-image: url('./img/marketing.jpg');
              &::before {
                content: '마케팅 콘텐츠 디자인';
              }
              &::after {
                content: 'Marketing ';
              }
            }
          }
        }
        &.quadruple {
          width: 530px;
          flex-wrap: wrap;
          .designRefImg {
            flex: 0 0 auto;
            width: 255px;
            padding-top: 255px;
            &:nth-of-type(2n) {
              margin-left: 20px;
            }
            &:nth-of-type(n + 3) {
              margin-top: 20px;
            }
            &.emoticon {
              background-image: url('./img/emoticon.jpg');
              &::before {
                content: '이모티콘 디자인';
              }
              &::after {
                content: 'Emoticon';
              }
            }
            &.animation {
              background-image: url('./img/animation.jpg');
              &::before {
                content: '애니메이션';
              }
              &::after {
                content: 'Animation';
              }
            }
            &.character {
              background-image: url('./img/character_sq.jpg');
              &::before {
                content: '캐릭터 디자인';
              }
              &::after {
                content: 'Character';
              }
            }
            &.conceptArt {
              background-image: url('./img/concept_art.jpg');
              &::before {
                content: '인물과 배경 컨셉아트';
              }
              &::after {
                content: 'Concept Art';
              }
            }
            &.productConcept {
              background-image: url('./img/product_concept.jpg');
              &::before {
                content: '제품 컨셉 디자인';
              }
              &::after {
                content: 'Product Concept';
              }
            }
            &.productMockup {
              background-image: url('./img/product_mockup.jpg');
              &::before {
                content: '제품 목업 디자인';
              }
              &::after {
                content: 'Product Mockup';
              }
            }
            &.brandGoods {
              background-image: url('./img/brand_goods.jpg');
              &::before {
                content: '브랜드 굿즈 디자인';
              }
              &::after {
                content: 'Brand Goods';
              }
            }
            &.editorialDesign {
              background-image: url('./img/editorial_design.jpg');
              &::before {
                content: '출판 인쇄 디자인';
              }
              &::after {
                content: 'Editorial Design';
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .switchingPage {
      [name='content5'] .contentWrapper .refImgWrapper.triple {
        justify-content: flex-start;
        cursor: default;
      }
      .contentWrapper {
        .descriptionWrapper {
          .content {
            font-size: 12px;
            &.footer {
              font-size: 8px;
              margin-top: 15px;
            }
          }
        }
        .refImgWrapper {
          margin-top: 96px;
          margin-left: -20px;
          &.double,
          &.triple,
          &.quadruple {
            width: 100vw;
            overflow-x: auto;
            flex-wrap: nowrap;
            .designRefImg {
              &:nth-of-type(2n) {
                margin-left: 0;
              }
              &:nth-of-type(n + 3) {
                margin-top: 0;
              }
              &:nth-of-type(n + 2) {
                margin-left: 10px;
              }
              &::before,
              &::after {
                font-size: 12px;
                letter-spacing: 0;
              }
            }
          }
          &.double {
            .designRefImg {
              width: 214px;
              padding-top: 400px;
            }
          }
          &.triple {
            .designRefImg {
              width: 139px;
              padding-top: 355px;
            }
          }
          &.quadruple {
            padding-bottom: 48px;
          }
        }
      }
    }
  }
}

.originalIpWrapper {
  .switchingPage {
    .pageSubMenu ul li {
      &.focused {
        color: $originalIpColor;
      }
    }
    .contentWrapper {
      .descriptionWrapper {
        .title {
          color: $originalIpColor;
        }
        .content {
          span {
            display: block;
            font-size: 20px;
            font-weight: 900;
            margin-bottom: 20px;
          }
          font-size: 14px;
          font-weight: 700;
          &.footer {
            margin-top: 180px;
            a {
              font-size: 22px;
              font-weight: 900;
              text-decoration: none;
              color: #fff;
              display: block;
              &:nth-of-type(n + 2) {
                margin-top: 12px;
              }
              img {
                margin-left: 12px;
                width: 20px;
              }
            }
          }
        }
      }
      .refImgWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        opacity: 0;
        .hiRefImg {
          position: relative;
          background-size: 105%;
          background-position: center center;
          background-repeat: no-repeat;
          transition: all 0.2s ease;
          &:hover {
            background-size: 120%;
            &::after {
              opacity: 1;
            }
            &::before {
              opacity: 1;
            }
          }
          &::before,
          &::after {
            position: absolute;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 30px;
            top: 0;
            left: 0;
            white-space: pre-wrap;
            word-wrap: break-word;
            line-height: 1.5;
          }
          &::before {
            opacity: 0;
            background-color: rgba(167, 81, 81, 0.65);
            font-size: 24px;
            font-weight: 900;
          }
          &::after {
            padding-top: 86px;
            opacity: 0;
            font-size: 12px;
            font-weight: 700;
          }
        }
        &.quadruple {
          width: 740px;
          flex-wrap: wrap;
          .hiRefImg {
            flex: 0 0 auto;
            width: 360px;
            padding-top: 300px;
            margin: 4.5px;
            &.exhibition {
              background-image: url('./img/exhibition.jpg');
              &::before {
                content: 'Exhibition';
              }
              &::after {
                content: '일상 속 다양한 감정을 표현하는 이모티콘을 소재로 행복을 다채로운 색감과 아트퍼니처가 가득한 공간으로 표현한 전시입니다.\A\A다양한 분야의 아티스트들과 협업하여 행복을 감각적인 전시 콘텐츠로서 관람객분들에게 선보였습니다';
              }
            }
            // &.happyKart {
            //   background-image: url('./img/happy_kart.jpg');
            //   &::before {
            //     content: 'Happy Kart';
            //   }
            //   &::after {
            //     content: '보고만 있어도 행복하고, 자꾸 만지고 싶은\A해피인사이드의 굿즈로 채워진 브랜드 스토어입니다.\A\A일상을 더욱 사랑스럽게 하는 해피인사이드 굿즈\A아이템을 카트에 담아 행복의 비밀을 함께 경험해보세요.';
            //   }
            // }
            &.vovelyTvLogo {
              background-image: url('./img/vovely_tv_logo.png');
              &::before {
                content: 'VOVELY TV';
              }
              &::after {
                content: '메타버스 세상 속 보블리가 만들어가는 드림 어드벤처 스토리가 담긴 유튜브 채널입니다. 보블리의 메타버스 세상 정복기를 지켜봐 주세요:D\A\AYOUTUBE : VOVELY TV';
              }
            }
            &.fivexo {
              background-image: url('./img/5xo.jpg');
              &::before {
                content: '5xO';
              }
              &::after {
                content: '5xO는 해피인사이드의 세계관을 담은 대표 캐릭터입니다.\A\A행복한 순간으로의 시간 여행을 통해 일상을 사랑스럽게\A하는 이모티콘들을 만나보세요!';
              }
            }
            &.pinkCastle {
              background-image: url('./img/pinkCastle.png');
              &::before {
                content: 'ZEPETO';
              }
              &::after {
                content: '메타버스 세상 속으로 들어간 보블리가 픽셀로 변신하여 제페토에서 새롭게 태어났습니다. 보블리 핑크캐슬을 시작으로 5xO까지! 보블리가 크리에이터로서 제작한 제페토 월드맵 속 드림 어드벤처가 펼쳐집니다.\A\A [1st DREAM ADVENTURE]\AVOVELY’S PINK CASTLE\A\A[ZEPETO CODE]\A 5DCZW7';
              }
            }
            // &.nextHappy {
            //   background-image: url('./img/next_happy.jpg');
            //   &::before {
            //     content: 'Next Happiness?';
            //   }
            //   &::after {
            //     content: '해피인사이드는 ‘헹복한 상상을 통해 일상을 사랑스럽게 만든다’는 브랜드 컨셉과 스토리 기반으로 IP 콘텐츠의 영역을 넓혀가고 있습니다.\A\A앞으로 다양한 채널에서 등장할 해피인사이드의 콘텐츠를 기대해주세요.';
            //   }
            // }
          }
        }
        &.double {
          width: 740px;
          flex-wrap: wrap;
          .hiRefImg {
            flex: 0 0 auto;
            width: 360px;
            padding-top: 600px;
            margin: 4.5px;
            &.exhibition2 {
              background-image: url('./img/exhibition2.jpg');
              &::before {
                content: 'Exhibition';
              }
              &::after {
                content: '‘Hi Ice Cream’을 아기자기한 컨셉의 공간으로 구현하여 \A선보인 전시 입니다. \A유명 미술 감독과의 콜라보레이션을 통해 컬러, 조명, \A미디어가 어우러진 디지털 아트웍을 전시 콘텐츠로 \A활용하였으며 [트렌드 코리아 2019]에서 주목할 만한 \A비주얼 전시로 소개 되었습니다. \A또한 비주얼 콘텐츠를 넘어 아이스크림을 직접 먹어보는 프로그램을 통해 관람객 분들께 ‘오감전시’로서 색다른 \A경험을 선사 하였습니다.';
              }
            }
            &.brandGoods {
              background-image: url('./img/brand_goods2.jpg');
              &::before {
                content: 'Brand Goods';
              }
              &::after {
                content: '시그니처 컬러인 핑크와 캐릭터를 바탕으로\A사랑스러운 색감과 아이스크림의 달콤함을 표현한\A브랜드 굿즈입니다.';
              }
            }
          }
        }
        &.triple {
          width: 740px;
          height: 738px;
          flex-direction: column;
          flex-wrap: wrap;
          .hiRefImg {
            flex: 0 0 auto;
            width: 360px;
            padding-top: 300px;
            margin: 4.5px;
            &.story {
              background-image: url('./img/story.jpg');
              &::before {
                content: 'Story';
              }
              &::after {
                content: '언제나 핑크를 향해 전력 질주를 꿈꾸지만\A몸이 따라주지 못해 발라당 넘어지고 마는 대형 오리 핑덕!\A\A핑덕의 이야기는 때로는 엉뚱하고, 때로는 귀여운\A에피소드들로 가득합니다.';
              }
            }
            &.story2 {
              background-image: url('./img/story2.jpg');
              &::before {
                content: '';
              }
              &::after {
                content: '';
              }
            }
            &.brandedContents {
              padding-top: 609px;
              background-image: url('./img/branded_content.jpg');
              &::before {
                content: 'Branded Contents';
              }
              &::after {
                content: '시그니처 컬러인 핑크와 귀여운 캐릭터 디자인을 바탕으로\A다양한 미디어 콘텐츠 및 굿즈 상품을 제작합니다.';
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .switchingPage {
      .contentWrapper {
        .descriptionWrapper {
          .content {
            font-size: 12px;
            span {
              font-size: 15px;
            }
            &.footer {
              margin-top: 40px;
              a {
                font-size: 15px;
                margin-left: auto;
                img {
                  width: 12px;
                }
              }
            }
          }
        }
        .refImgWrapper {
          margin: 40px 0 0 -20px;
          flex-direction: column;
          .hiRefImg {
          }
          &.quadruple,
          &.double,
          &.triple {
            width: 100vw;
            .hiRefImg {
              margin: 0;
              width: 100%;
            }
          }
          &.quadruple {
            .hiRefImg {
              padding-top: 83%;
            }
          }
          &.double {
            .hiRefImg {
              padding-top: 166%;
            }
          }
          &.triple {
            height: auto;
            .hiRefImg {
              padding-top: 82%;
              &.brandedContents {
                padding-top: 169%;
              }
            }
          }
        }
      }
    }
  }
}
