* {
  font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
  // scrollbar hidden
  &::-webkit-scrollbar {
    display: none; /* chrome */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  line-height: 1.5 !important;
  word-break: keep-all !important;
}

input,
textarea {
  font: inherit;
  color: inherit;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid #5c5c5c;
  border-radius: 0;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  // border: 1px solid #5c5c5c;
  resize: none;
}

a,
button {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
}

.LoadingWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  img {
    width: 240px;
    height: auto;
    @media screen and (max-width: 640px) {
      width: 120px;
    }
  }
}

.fixedBg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -999;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.downArrow {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    animation: upAndDown 1.2s ease infinite;
  }

  @media screen and (max-width: 1280px) and (min-width: 641px) {
    transform: scale(0.75) translate(-62.5%, -0);
  }
  @media screen and (max-width: 640px) {
    width: 40px;
    svg {
      width: 100%;
    }
  }

  @keyframes upAndDown {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(25%);
    }
  }
}
