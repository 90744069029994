header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fill-available;
  z-index: 99999;
  position: fixed;
  top: 0;
  padding: 36px 20px 0;
  left: 50%;
  transform: translate(-50%, 0);
  @media screen and(max-width: 1280px) and (min-width: 641px) {
    padding: 28px 16px 0;
    .logo,
    [display='visible'],
    button {
      transform: scale(0.75);
    }
  }
  @media screen and (max-width: 640px) {
    padding: 24px 20px 0;
    width: 100%;
    max-width: none;
    box-sizing: border-box;
    a {
      .logo {
        svg {
          width: 32px;
        }
      }
    }
    button {
      transform: scale(0.66);
    }
  }
}
