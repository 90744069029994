.about {
  height: calc(var(--vh, 1vh) * 100);
  .rock_background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &.background {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -10;
        background-color: #000;
      }
    }
  }

  .fixed_material {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    .contents {
      position: fixed;
      opacity: 0;
      font-size: 36px;
      font-weight: 700;
      opacity: 0;
    }

    .section1 {
      font-size: 60px;
      font-weight: 900;
      box-sizing: border-box;
      word-break: keep-all;
      opacity: 0;
      br:nth-of-type(3) {
        display: none;
      }
      .red {
        color: #ff4929;
      }
    }

    .section2 {
      &.contents {
        top: 20%;
        left: 20%;
        .header {
          font-weight: 900;
        }
        .detail {
          margin-top: 30px;
        }
      }
    }

    .section3 {
      &.contents {
        bottom: 20%;
        right: 20%;
      }
    }
    .dream_section,
    .dare_section {
      position: fixed;
      top: 50%;
      left: 50%;
      margin-top: -180px;
      margin-left: -180px;
      width: 360px;
      height: 360px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .dare_section {
      img {
      }

      &.line {
        opacity: 0;
      }

      .dare {
        transform: scale(0);
        opacity: 0;
      }
      .front {
        z-index: 10;
      }
      .back {
        z-index: -10;
      }
    }
    .dream_section {
      opacity: 0;
      transform: scale(1);
      z-index: 10;
      img {
      }
      .front {
        z-index: 10;
      }
      .back {
        z-index: -10;
      }
    }
  }
  @media screen and (max-width: 1280px) and (min-width: 641px) {
    .section1.contents,
    .section2.contents,
    .section3.contents {
      transform: scale(0.6);
    }
  }

  @media screen and (max-width: 640px) {
    .fixed_material {
      .contents {
        font-size: 15px;
      }
      .section1 {
        font-size: 30px;
        padding: 0 20px;
        br:nth-of-type(3) {
          display: inline;
        }
      }
      .section2 {
        &.contents {
          top: 15%;
          left: 20px;
        }
      }
      .section3 {
        &.contents {
          bottom: 10%;
          right: 20px;
        }
      }
      .dream_section,
      .dare_section {
        margin-top: -150px;
        margin-left: -150px;
        width: 300px;
        height: 300px;
      }
      .link {
      }
    }
  }

  section {
    height: calc(var(--vh, 1vh) * 200);
    max-width: 1280px;
    margin: 0 auto;
    position: relative;

    &#section_1 {
      height: calc(var(--vh, 1vh) * 200);
    }
    &#section_4 {
      height: calc(var(--vh, 1vh) * 200);
    }

    &#section_brandstroy {
      margin-top: 240px;
      height: calc(var(--vh, 1vh) * 100);
      // box-sizing: border-box;
      // padding: 560px 0 0 0;
    }

    &#section_5 {
      height: auto;
      box-sizing: border-box;
      padding: 240px 0 0 0;
    }
    &#section_6 {
      height: auto;
      box-sizing: border-box;
      padding: 120px 0 480px 0; // height: 를 auto로 잡으면 어떻게 되는지?
    }
    &#section_7,
    &#section_8 {
      height: auto;
    }

    &#section_brandstroy,
    &#section_5,
    &#section_6,
    &#section_7 {
      z-index: 999;
    }

    &#section_1,
    &#section_2,
    &#section_3,
    &#section_4,
    &#section_brandstroy,
    &#section_5,
    &#section_7,
    &#section_8 {
      .empty {
        position: absolute;
        top: 20px;
        height: 10%;
      }
    }

    &#section_5 {
      color: white;
    }

    &#section_brandstroy {
      .contents {
        // opacity: 0;
      }
    }

    &#section_6 {
      color: white;

      .empty {
        position: absolute;
        top: 50%;
      }
    }
    @media screen and(max-width: 1280px) and (min-width: 641px) {
      &#section_6 {
        padding: 120px 0 560px 0; // height: 를 auto로 잡으면 어떻게 되는지?
      }
    }
    @media screen and (max-width: 640px) {
      max-width: none;
      width: 100%;

      &#section_brandstroy {
        margin-top: 240px;
        height: auto;
      }
      &#section_6 {
        padding: 72px 0 360px 0; // height: 를 auto로 잡으면 어떻게 되는지?
      }
    }
  }
}

.show {
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-delay: 0;
  transition-timing-function: ease;
  animation-fill-mode: forwards;
  animation-name: fadeInUp;
}

.disappear {
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-delay: 0;
  transition-timing-function: ease;
  animation-fill-mode: forwards;
  animation-name: fadeOutUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
}

section {
  .title {
    font-size: 36px;
    font-weight: 900;
    color: #ff4929;
  }
  .description {
    margin-top: 48px;
    font-size: 20px;
    font-weight: 900;
    line-height: 1.75;
    span {
      color: #ff4929;
    }
  }
  &#section_5 .description {
    br:nth-of-type(2) {
      display: none;
    }
  }
  .brandStoryWrapper {
    padding: 72px;
    .iframeWrapper {
      width: 100%;
      padding-top: 56.4%;
      position: relative;
      iframe {
        display: block;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .link {
      color: white;
      font-weight: 900;
      font-size: 24px;
      margin: 72px 0 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        z-index: 100;
        position: relative;
        width: 20px;
        margin-left: 12px;
      }
    }
  }
  .deptInfo {
    display: flex;
    margin-top: 124px;
    .operationDept {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 840px;
      .singleDept {
        flex: 0 0 auto;
        width: 240px;
        word-break: keep-all;
        text-align: center;
        &:nth-of-type(n + 4) {
          margin-top: 54px;
        }
        .deptName {
          font-size: 20px;
          font-weight: 900;
          padding: 12px 0;
          position: relative;
          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-image: linear-gradient(
              90deg,
              rgba(255, 73, 41, 1) 0%,
              rgba(255, 69, 210, 1) 100%
            );
          }
        }
        .deptDescription {
          font-size: 14px;
          font-weight: 500;
          &.first {
            margin-top: 20px;
          }
        }
        .deptDetail {
          font-size: 14px;
          margin-top: 30px;
          span {
            padding: 0 4px 0 0;
          }
        }
      }
    }
    .labDept {
      margin: 0 auto;
      text-align: center;
      .deptName {
        color: #ff4929;
        font-size: 20px;
        font-weight: 900;
        padding: 12px 0;
        position: relative;
      }
      .deptDescription {
        font-size: 14px;
        font-weight: 500;
        &.first {
          margin-top: 20px;
        }
      }
      .deptDetail {
        font-size: 14px;
        margin-top: 30px;
        span {
          padding: 0 4px 0 0;
        }
      }
    }
  }
  .contentsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .valueWrapper {
      margin: 96px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      span {
        // width: 33.3%;
        font-size: 72px;
        font-weight: 900;
      }
    }
    .mission {
      align-self: flex-end;
      font-size: 40px;
      font-weight: 900;
      // text-align: center;
      line-height: 1.5;
      span {
        color: #ff4929;
      }
    }
    .circles {
      margin: 72px 0;
      width: 48px;
      height: 72px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .vision {
      font-size: 60px;
      font-weight: 900;
      color: #ff4929;
      br {
        display: none;
      }
    }
  }
  .historyWrapper {
    margin: 40px auto 200px;
    .history {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      br {
        display: none;
      }
    }
    .historyList {
      width: 75%;
      margin: 72px auto;
      overflow: hidden;
      table {
        text-align: left;
        tr {
          display: block;
          display: flex;
          &:nth-of-type(n + 2) {
            padding-top: 24px;
          }
          td.year {
            width: 6%;
            font-size: 14px;
            font-weight: 900;
            padding: 6px;
            box-sizing: border-box;
          }
          td.content {
            width: 94%;
            display: flex;
            flex-wrap: wrap;
            font-size: 14px;
            font-weight: 500;

            span {
              display: block;
              width: 50%;
              padding: 6px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
    .historyMore {
      font-size: 22px;
      font-weight: 900;
      display: block;
      margin: 0 auto;
      position: relative;
      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #000;
      }
    }
  }
  .partnersWrapper {
    margin: 24px auto;
    padding-bottom: 96px;
    width: 100%;
    position: relative;
    .partnerPage {
      width: 75%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .slick-slider {
        width: 100%;
        .slick-prev {
          display: none !important;
        }
        .slick-list {
          .slick-track {
            .slick-slide {
              & > div {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .partner {
                  width: 16.5%;
                  padding-top: 16.5%;
                  flex: 0 0 auto;
                  position: relative;
                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
              &[data-index='1'] {
                & > div {
                  .partner {
                    &:nth-of-type(n + 20) {
                      img {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .slick-next {
          display: none !important;
        }
        .slick-dots {
        }
      }
    }
  }
  .flip {
    display: flex;
    margin: 0 auto;
    position: relative;
    &::before,
    &::after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background-color: #000;
      border-radius: 50%;
    }
    &::after {
      margin-left: 24px;
    }
  }
  @media screen and (max-width: 1280px) and (min-width: 641px) {
    &#section_brandstroy,
    &#section_5,
    &#section_6,
    &#section_7,
    &#section_8 {
      transform: scale(0.75);
    }
  }
  @media screen and (max-width: 640px) {
    .title,
    .description {
      font-size: 15px;
      padding-left: 20px;
    }
    &#section_6 .title {
      display: none;
    }
    &#section_5 .description {
      br:nth-of-type(2) {
        display: inline;
      }
      br:nth-of-type(3) {
        display: none;
      }
    }
    .brandStoryWrapper {
      padding: 48px 0;
      .link {
        font-size: 22px;
        margin: 48px 0 0;
        img {
          width: 18px;
          margin-left: 8px;
        }
      }
    }
    .deptInfo {
      margin-top: 72px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .operationDept {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .singleDept {
          &:nth-of-type(n + 2) {
            margin-top: 56px;
          }
          .deptName {
            font-size: 15px;
          }
          .deptDescription {
            font-size: 12px;
          }
          .deptDetail {
            font-size: 12px;
            margin-top: 10px;
          }
        }
      }
      .labDept {
        margin: 64px 0 0 0;
        .deptName {
          font-size: 15px;
        }
        .deptDescription {
          font-size: 12px;
        }
        .deptDetail {
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
    .contentsWrapper {
      .valueWrapper {
        padding-left: 20px;
        margin: 48px 0;
        box-sizing: border-box;
        span {
          width: 100%;
          font-size: 44px;
          &:nth-of-type(n + 2) {
            margin-top: 0;
          }
        }
      }
      .mission {
        font-size: 15px;
        padding-right: 20px;
      }
      .circles {
        margin: 48px 0;
        width: 36px;
        height: 48px;
      }
      .vision {
        font-size: 30px;
        text-align: center;
        br {
          display: inline;
        }
      }
    }
    .historyWrapper {
      margin: 20px auto 100px;
      .history {
        font-size: 15px;
        text-align: left;
        padding-left: 20px;
        font-weight: 900;
        br {
          display: inline;
        }
      }
      .historyList {
        width: calc(100% - 40px);
        margin: 36px auto;
        table {
          tr {
            td.year {
              width: 15%;
              font-size: 12px;
              padding: 6px 3px;
            }
            td.content {
              width: 85%;
              font-size: 12px;
              span {
                width: 100%;
                padding: 6 px 3px;
              }
            }
          }
        }
      }
    }
    .partnersWrapper {
      .partnerPage {
        width: calc(100% - 40px);
        .slick-slider {
          width: 100%;
          .slick-list {
            .slick-track {
              .slick-slide {
                & > div {
                  .partner {
                    width: 25%;
                    padding-top: 25%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .flip {
      &::before,
      &::after {
        width: 8px;
        height: 8px;
      }
      &::after {
        margin-left: 18px;
      }
    }
  }
}

.brandstory {
  z-index: 99999 !important;
  .MuiDialog-scrollPaper {
    align-items: flex-start;
    margin-top: 24px;
    .MuiPaper-root {
      max-width: none;
      max-height: none;
      margin: 0;
      border-radius: 0;
      .MuiDialogContent-root {
        width: 640px;
        height: 900px;
        padding: 0px;
        box-sizing: border-box;
        overflow-y: auto;
        & > img {
          width: 100%;
          height: auto;
          display: block;
          margin: 0;
          padding: 0;
        }

        button {
          position: absolute;
          top: 24px;
          right: 24px;
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            &.mobile {
              display: none;
            }
          }
        }
      }
    }
    @media screen and(max-width: 1280px) and (min-width: 641px) {
      transform: scale(0.6);
      transform-origin: top;
    }
    @media screen and (max-width: 640px) {
      margin-top: 0;
      .MuiPaper-root {
        max-height: calc(100% - 0.1px);
        .MuiDialogContent-root {
          width: 100%;
          height: 100%;
          button {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
