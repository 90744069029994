.menuBtnStyle {
  position: relative;
  font-size: 48px;
  font-weight: 900;
  color: #fff;
  opacity: 0.7;
  transition: all 0.3s ease;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -10px;
    left: 0;
    background-image: linear-gradient(90deg, rgba(0, 185, 239, 1) 0%, rgba(255, 69, 210, 1) 100%);
    transition: all 0.3s ease;
  }
  &:hover {
    opacity: 1;
    &::after {
      width: 100%;
    }
  }
  @media screen and (max-width: 640px) {
    font-size: 32px;
    &::after {
      bottom: -2px;
    }
  }
}

.mainMenu {
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #000;
  z-index: 9999;
  .menuWrapper {
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .mainMenuLink {
      &:nth-of-type(n + 2) {
        margin-top: 40px;
      }
    }
    .businessMenu {
      margin-top: 40px;
      .menuBtnStyle {
        .businessSubMenu {
          position: absolute;
          top: 0;
          left: calc(100% + 360px);
          display: flex;
          flex-direction: column;
          .menuBtnStyle {
            font-size: 36px;
            white-space: nowrap;
            width: fit-content;
            &:nth-of-type(n + 2) {
              margin-top: 24px;
            }
            span {
              position: absolute;
              font-size: 20px;
              top: 50%;
              left: -48px;
              transform: translate(0, -50%);
            }
          }
        }
      }
    }

    .menuFooter {
      display: flex;
      width: 100%;
      margin-top: 96px;
      .info {
        width: 50%;
        p {
          font-size: 14px;
          color: #fff;
          line-height: 2;
          &:nth-of-type(n + 2) {
            margin-top: 18px;
          }
        }
      }
      .snsWrapper {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        a {
          font-size: 14px;
          color: #fff;
          font-weight: 900;
          &:nth-of-type(n + 2) {
            margin-left: 36px;
          }
          span {
            margin-left: 8px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1280px) and (min-width: 641px) {
    .menuWrapper {
      // transform: scale(0.7);
      transform: scale(0.6);
    }
  }
  @media screen and (max-width: 640px) {
    .menuWrapper {
      align-items: flex-start;
      width: calc(100% - 40px);
      .mainMenuLink {
        &:first-of-type {
          margin-top: 128px;
        }
        &:nth-of-type(n + 2) {
          margin-top: 28px;
        }
      }
      .businessMenu {
        margin-top: 20px;
        .menuBtnStyle {
          .businessSubMenu {
            position: absolute;
            top: 27px;
            left: 168px;
            .menuBtnStyle {
              font-size: 14px;
              padding-left: 24px;
              &:nth-of-type(n + 2) {
                margin-top: 16px;
              }
              span {
                font-size: 12px;
                left: 0;
              }
            }
          }
        }
      }
      .menuFooter {
        margin: auto 0 36px 0;
        .info {
          display: none;
        }
        .snsWrapper {
          width: 100%;
          justify-content: space-between;
          align-items: center;
          a {
            &:nth-of-type(n + 2) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

.subMenu {
  // 포트폴리오의 서브 메뉴임...
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 calc((100% - 1280px) / 2); // need max-width..
  box-sizing: border-box;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  & > div {
    @media screen and (max-width: 1280px) and (min-width: 641px) {
      padding-top: 56px;
      transform: scale(0.7);
    }
  }
  button {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 48px;
    right: 48px;
    @media screen and (max-width: 640px) {
      top: 24px;
      right: 20px;
      transform: scale(0.7);
    }
    opacity: 0.7;
    transition: all 0.3s ease;
    &:hover {
      opacity: 1;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      background-color: #fff;
      width: 24px;
      height: 3px;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  .portfolioBtn {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 580px;
    cursor: pointer;
    &:nth-of-type(n + 2) {
      margin-top: 40px;
    }
    .menuBtnStyle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        font-size: 20px;
        position: absolute;
        left: -72px;
      }
    }
  }
  @media screen and (max-width: 640px) {
    padding: 0;
    button {
      top: 24px;
      right: 20px;
      transform: scale(0.7);
    }
    .portfolioBtn {
      width: 100%;
      justify-content: center;
      &:nth-of-type(n + 2) {
        margin-top: 30px;
      }
      .menuBtnStyle {
        font-size: 30px;
        span {
          display: none;
        }
      }
    }
  }
}
