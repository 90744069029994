.portfolioPageWrapper {
  width: 1280px;
  padding-top: 180px;
  margin: 0 auto;
  @media screen and(max-width: 1280px) and (min-width: 641px) {
    transform: scale(0.7);
    transform-origin: top;
  }
  @media screen and (max-width: 640px) {
    width: calc(100% - 40px);
    padding-top: 124px;
  }
  .addPortfolio {
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: 72px;
    height: 72px;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: all 0.3s ease;
    z-index: 100;
    &:hover {
      transform: translate(0, -8px);
    }
  }
  .portFolioFilterWrapper {
    display: flex;
    @media screen and (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
    }
    justify-content: space-between;
    align-items: flex-end;
    .mainFilter {
      @media screen and (max-width: 640px) {
        font-size: 10px;
        margin-bottom: 20px;
      }
      label {
        font-size: 18px;
        font-weight: 500;
        @media screen and (max-width: 640px) {
          font-size: 10px;
        }
      }
      #filterValue {
        margin-top: 10px;
        font-size: 36px;
        @media screen and (max-width: 640px) {
          font-size: 20px;
        }
        font-weight: 900;
        position: relative;
        cursor: pointer;
        span {
          margin-left: 32px;
          img {
            width: 20px;
            height: 20px;
            object-fit: cover;
          }
        }
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 3px;
          bottom: 0px;
          left: 0;
          background-image: linear-gradient(
            90deg,
            rgba(0, 185, 239, 1) 0%,
            rgba(255, 69, 210, 1) 100%
          );
        }
      }
    }
    .portfolioSearchWrapper {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #fff;
      border-radius: 24px;
      @media screen and (max-width: 640px) {
        padding: 0 4 px;
      }
      padding: 0 8px;
      border: 1px solid #e2e2e2;
      input {
        border: none;
        font-size: 18px;
        @media screen and (max-width: 640px) {
          font-size: 14px;
        }
        padding: 4px;
      }
      button {
        width: 24px;
        height: 24px;
        @media screen and (max-width: 640px) {
          width: 20px;
          height: 20px;
          padding: 4px;
        }
        padding: 8px;
        box-sizing: content-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .hybridEventValue {
      span {
        font-size: 20px;
        font-weight: 900;
        @media screen and (max-width: 640px) {
          font-size: 11px;
        }
        cursor: pointer;
        &:nth-of-type(n + 2)::before {
          content: '|';
          font-weight: 200;
          color: #000;
          margin: 0 24px;
          @media screen and (max-width: 640px) {
            margin: 0 8px;
          }
        }
      }
    }
  }
  .portFolioWrapper {
    width: 1280px; // need max-width..
    margin: 48px auto;
    @media screen and (max-width: 640px) {
      width: 100%;
    }
    padding-bottom: 72px;
    display: flex;
    flex-wrap: wrap;
    .singlePortFolioTileWrapper {
      &:nth-of-type(n + 2) {
        margin-left: 20px;
      }
      &:nth-of-type(n + 5) {
        margin-top: 20px;
      }
      &:nth-of-type(4n + 1) {
        margin-left: 0px;
      }
      @media screen and (max-width: 640px) {
        &:nth-of-type(n + 2) {
          margin-left: 0;
        }
        &:nth-of-type(n + 5) {
          margin-top: 0;
        }
        &:nth-of-type(4n + 1) {
          margin-left: 0;
        }
        &:nth-of-type(2n) {
          margin-left: 10px;
        }
        &:nth-of-type(n + 3) {
          margin-top: 10px;
        }
      }
      width: calc(1220px / 4);
      padding-top: calc((1220px / 4) * 1.38);
      @media screen and (max-width: 640px) {
        width: calc(50% - 5px);
        padding-top: calc((50% - 4px) * 1.38);
      }
      position: relative;
      overflow: hidden;
      cursor: pointer;
      .singlePortFolioTileImg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &:hover {
        .singlePortFolioTileInfo {
          left: 0%;
        }
      }
      .singlePortFolioTileInfo {
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        background: linear-gradient(
          135deg,
          rgba(0, 185, 239, 0.5) 0%,
          rgba(255, 69, 210, 0.5) 100%
        );
        padding: 30px;
        @media screen and (max-width: 640px) {
          padding: 16px;
        }
        box-sizing: border-box;
        color: #fff;
        display: flex;
        flex-direction: column;
        .title {
          font-size: 24px;
          @media screen and (max-width: 640px) {
            font-size: 12px;
          }
          font-weight: 900;
        }
        .categoryWrapper {
          margin-top: auto;
          font-size: 14px;
          @media screen and (max-width: 640px) {
            font-size: 8px;
          }
          font-weight: 500;
          display: flex;
          flex-direction: column;
          span {
            display: block;
            &:nth-of-type(n + 2) {
              margin-top: 12px;
            }
          }
        }
        .linkMarker {
          position: absolute;
          bottom: 30px;
          right: 30px;
          width: 28px;
          height: 28px;
          @media screen and (max-width: 640px) {
            bottom: 16px;
            right: 16px;
            width: 12px;
            height: 12px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .seeMore {
      font-size: 36px;
      @media screen and (max-width: 640px) {
        font-size: 16px;
      }
      font-weight: 900;
      margin: 48px auto;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #000;
      }
    }
  }
}

.singlePortfolio {
  z-index: 99999 !important;
  .MuiDialog-scrollPaper {
    align-items: flex-start;
    margin-top: 24px;
    .MuiPaper-root {
      max-width: none;
      max-height: none;
      margin: 0;
      border-radius: 0;
      .MuiDialogContent-root {
        width: 1280px;
        height: 900px;
        padding: 0;
        display: flex;
        position: relative;
        button {
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            &.mobile {
              display: none;
            }
          }
        }
        .singlePortUI {
          position: absolute;
          top: 24px;
          right: 24px;
          display: flex;
          align-items: center;
          button {
            &:nth-of-type(n + 2) {
              margin-left: 24px;
            }
            &:last-of-type {
              img {
                transform: scale(0.8);
              }
            }
          }
        }
        .imgsWrapper {
          width: 800px;
          background-color: #e2e2e2;
          &.addImg {
            overflow-y: auto;
            padding: 64px;
            .hiddenImgSelect {
              display: none;
            }
            label {
              display: block;
              padding: 0 8px;
              cursor: pointer;
            }
            & > div {
              margin: 8px;
            }
          }
          .slick-slider {
            height: 100%;
            * {
              height: 100%;
            }
            .slick-list {
              .slick-track {
                .slick-slide {
                  & > div {
                    .imgWrapper {
                      outline: none;
                      .slider-image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }
                    }
                  }
                }
              }
            }
            .slick-prev {
              left: 24px;
              z-index: 10;
              height: auto;
              &:before {
                content: '';
                background-image: url('./img/prev.svg');
                background-size: cover;
                width: 15px;
                height: 30px;
                display: block;
              }
            }
            .slick-next {
              right: 24px;
              z-index: 10;
              height: auto;
              &:before {
                content: '';
                background-image: url('./img/next.svg');
                background-size: cover;
                width: 15px;
                height: 30px;
                display: block;
              }
            }
            .slick-dots {
              bottom: 60px;
              z-index: 10;
              height: auto;
              li button::before {
                opacity: 0.5;
                color: #fff;
              }
              li.slick-active button::before {
                opacity: 1;
                color: #fff;
              }
            }
          }
        }
        .contentWrapper {
          width: 480px;
          padding: 64px;
          display: flex;
          flex-direction: column;
          & > div:nth-of-type(n + 2) {
            margin-top: 24px;
          }
          .title {
            font-size: 32px;
            font-weight: 900;
          }
          .categorySelectWrapper {
            display: flex;
            justify-content: flex-start;
            font-size: 14px;
            font-weight: 700;
            color: #7c7c7c;
            .mainCategory {
              width: 50%;
            }
            .subCategory {
              width: 50%;
            }
            .singleCategory {
              padding-right: 4px;
              position: relative;
              display: flex;
              &:nth-of-type(n + 2)::before {
                content: '/';
                display: block;
                position: relative;
                padding-right: 4px;
              }
            }
          }
          .contentGeneral {
            font-size: 14px;
            font-weight: 700;
            display: flex;
            label {
              color: #7c7c7c;
            }
            input,
            .generalContent {
              width: 80%;
              margin-left: auto;
            }
            .periodSelectWrapper {
              display: flex;
              input {
                width: 40%;
                margin-left: 0;
              }
              span {
                margin: 0 8px;
              }
            }
          }
          .client {
            &.contentGeneral {
              margin-top: 48px;
            }
          }
          .period {
            .periodSelectWrapper {
              width: 80%;
              margin-left: auto;
              display: flex;
            }
          }
          .description {
            &.viewOnly {
              margin-top: 48px;
            }
            font-size: 14px;
            line-height: 1.75;
            textarea {
              width: 100%;
              height: 120px;
            }
          }
          .deptWrapper,
          .memberWrapper {
            &.viewOnly {
              display: block;
              margin-top: auto;
              .dept {
                font-size: 12px;
                font-weight: 700;
                margin-top: 12px;
                span {
                  &:nth-of-type(n + 2):before {
                    content: '/';
                    display: inline-block;
                    margin: 0 8px;
                  }
                }
              }
              .member {
                margin-top: 24px;
                font-size: 12px;
                font-weight: 700;
                display: flex;
                flex-wrap: wrap;
                span {
                  display: block;
                  width: 25%;
                  &:nth-of-type(n + 5) {
                    margin-top: 8px;
                  }
                }
              }
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            font-size: 14px;
            font-weight: 700;
            label {
              font-size: 15px;
              font-weight: 900;
            }
            .teamList,
            .memberList {
              margin-top: 12px;
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              .inputTeamAndMeber {
                padding: 4px;
                width: 25%;
                box-sizing: border-box;
                position: relative;
                button {
                  position: absolute;
                  right: 0;
                  transform: scale(0.6);
                }
              }
            }
          }
        }
      }
    }
    @media screen and(max-width: 1280px) and (min-width: 641px) {
      transform: scale(0.6);
      transform-origin: top;
    }
    @media screen and (max-width: 640px) {
      margin-top: 0;
      .MuiPaper-root {
        .MuiDialogContent-root {
          width: 100%;
          height: 100%;
          flex-direction: column;
          &::before,
          &::after {
            content: '';
            width: 100%;
            height: 48px;
            position: absolute;
            left: 0;
            z-index: 10;
          }
          &::before {
            top: 300px;
            background: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 1) 0%,
              rgba(255, 255, 255, 0) 100%
            );
          }
          &::after {
            bottom: 0;
            background: linear-gradient(
              to top,
              rgba(255, 255, 255, 1) 0%,
              rgba(255, 255, 255, 0) 100%
            );
          }
          button {
            width: 16px;
            height: 16px;
            img {
              &.mobile {
                display: block;
              }
              &.notmobile {
                display: none;
              }
            }
          }
          .singlePortUI {
            z-index: 10;
            top: 24px;
            right: 20px;
          }
          .imgsWrapper {
            width: 100%;
            height: 300px;
            position: relative;
            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.1);
            }
            .slick-slider {
              .slick-list {
                .slick-track {
                }
              }
              .slick-prev {
                left: 8px;
              }
              .slick-next {
                right: 8px;
              }
              .slick-dots {
                bottom: 12px;
              }
            }
          }
          .contentWrapper {
            width: 100%;
            padding: 40px 20px;
            height: calc((var(--vh, 1vh) * 100) - 300px);
            overflow-y: auto;
            box-sizing: border-box;
            position: relative;

            & > div:nth-of-type(n + 2) {
              margin-top: 20px;
            }
            .title {
              font-size: 30px;
            }
            .categorySelectWrapper {
              font-size: 12px;
            }
            .contentGeneral {
              font-size: 12px;
            }
            .description {
              &.viewOnly {
                margin-top: 30px;
              }
              font-size: 12px;
            }
            .deptWrapper {
              &.viewOnly {
                margin-top: 48px;
              }
            }
          }
        }
      }
    }
  }
}

.datePickerWrapper {
  z-index: 999999 !important;
}
