.businessSubMenu.onPage {
  margin: 180px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: fit-content;
  .menuBtnStyle {
    font-size: 50px;
    // width: 580px;
    &:nth-of-type(n + 2) {
      margin-top: 40px;
    }
    span {
      position: absolute;
      font-size: 20px;
      top: 50%;
      left: -48px;
      transform: translate(0, -50%);
    }
  }
}
