.bgContact {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -9;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.contactWrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 180px;
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  @media screen and(max-width: 1280px) and (min-width: 641px) {
    transform-origin: top;
    transform: scale(0.7);
  }
  @media screen and(max-width: 640px) {
    max-width: none;
    width: calc(100% - 40px);
    height: auto;
    padding-top: 124px;
    display: block;
  }
  .infoWrapper {
    width: 100%;
    @media screen and(max-width: 640px) {
      width: 100%;
    }
    & > .title {
      font-size: 86px;
      font-weight: 900;
      span {
        display: none;
      }
      @media screen and(max-width: 640px) {
        font-size: 50px;
        word-break: break-word !important;

        span {
          display: inline;
        }
      }
    }
    .mailAddress {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 700;
      @media screen and(max-width: 640px) {
        margin-top: 60px;
        font-size: 15px;
      }
    }
    .contactUs {
      display: block;
      margin-top: 24px;
      font-size: 20px;
      font-weight: 900;
      padding: 8px 36px;
      color: #fff;
      border-radius: 24px;
      background-color: #000;
      @media screen and(max-width: 640px) {
        margin: 48px auto 0;
      }
    }
    .locationWrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 56px;
      @media screen and(max-width: 640px) {
        flex-direction: column;
      }
      .loctionAddress {
        width: 40%;
        font-size: 14px;
        font-weight: 500;
        @media screen and(max-width: 640px) {
          font-size: 12px;
          width: 100%;
          &:last-of-type {
            margin: 48px 0;
          }
        }
        & > .title {
          font-size: 20px;
          font-weight: 700;
          @media screen and(max-width: 640px) {
            font-size: 15px;
          }
        }
        &::after {
          content: '';
          display: block;
          clear: both;
        }
        .tel,
        .address {
          margin-top: 14px;
          float: left;
          @media screen and(max-width: 640px) {
            float: none;
          }
          line-height: 1.8;
          span {
            display: block;
            line-height: inherit;
            @media screen and(max-width: 640px) {
              display: block;
            }
            &:nth-of-type(n + 2) {
              margin-top: 16px;
            }
          }
        }
        .tel {
          width: 40%;
          @media screen and(max-width: 640px) {
            width: 100%;
          }
        }
        .address {
          width: 60%;
          @media screen and(max-width: 640px) {
            width: 100%;
          }
        }
      }
    }
  }
}

.MuiDialog-root {
  .MuiDialog-container {
    .MuiPaper-root {
      border-radius: 0;
      max-width: none;
      max-height: none;
      margin: 0;
      .closeBtn {
        position: absolute;
        top: 24px;
        right: 24px;
        img {
          width: 100%;
          height: 100%;
        }
        @media screen and(max-width: 640px) {
          width: 16px;
          height: 16px;

          top: 72px;
          right: 20px;
        }
      }
      .MuiDialogContent-root.mailWrapper {
        width: 520px;
        box-sizing: border-box;
        padding: 96px 64px 64px 64px;
        max-height: 720px;
        @media screen and(max-width: 1280px) and (min-width: 641px) {
          max-height: 500px;
        }
        @media screen and(max-width: 640px) {
          width: 100%;
          max-height: calc(var(--vh, 1vh) * 100);
          padding: 124px 32px 32px 32px;
        }
        &::-webkit-scrollbar {
          display: block;
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.4);
          border-radius: 3px;
          border: 1px solid #fff;
        }

        &::-webkit-scrollbar-track-piece:start {
          background-color: transparent;
        }

        &::-webkit-scrollbar-track-piece:end {
          background-color: transparent;
        }

        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 20px;
          font-weight: 900;
          span {
            color: #7c7c7c;
            font-size: 12px;
            font-weight: 500;
          }
        }
        input,
        textarea {
          padding: 8px 0;
          margin-top: 20px;
          &::placeholder {
            font-weight: 500;
            font-size: 14px;
            color: #7c7c7c;
          }
        }
        input[type='file'] {
          display: none;
        }
        label.businessArea {
          display: block;
          margin: 20px 0 12px;
          font-weight: 500;
          font-size: 14px;
          color: #7c7c7c;
        }
        div.businessArea {
          border-bottom: 1px solid #5c5c5c;
          display: flex;
          flex-wrap: wrap;
          span {
            display: block;
            font-size: 12px;
            padding: 6px 10px;
            border-radius: 18px;
            margin: 0.5%;
          }
        }
        // .multiSelect {
        //   margin-top: 24px;
        //   width: 100%;
        //   font-weight: 500;
        //   label {
        //     font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
        //     font-weight: 500;
        //     font-size: 14px;
        //     color: #7c7c7c;
        //   }
        //   &::after {
        //     content: '';
        //     position: absolute;
        //     right: 0;
        //     bottom: 8px;
        //     width: 24px;
        //     height: 24px;
        //     background-image: url('./img/down_arrow.svg');
        //     background-size: cover;
        //   }
        //   .MuiFormLabel-root.Mui-focused {
        //     color: #000;
        //   }
        //   .MuiInput-root {
        //     .MuiSelect-select {
        //       & > div {
        //         display: flex;
        //         flex-wrap: wrap;
        //         .MuiChip-root {
        //           margin: 1px;
        //         }
        //       }
        //       &:focus {
        //         background-color: transparent;
        //       }
        //     }
        //     &.MuiInput-underline {
        //       &::before {
        //         opacity: 0;
        //       }
        //       &::after {
        //         opacity: 0;
        //       }
        //     }
        //     &.MuiInput-underline {
        //       border-bottom: 1px solid #5c5c5c;
        //     }
        //   }
        //   svg {
        //     display: none;
        //   }
        // }
        .attachWrapper {
          margin-top: 20px;

          label {
            font-size: 14px;
            font-weight: 500;
            color: #7c7c7c;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
          }
          button {
            width: 18px;
            height: 18px;
          }
          .attachFile {
            padding: 8px;
            background-color: #e0e0e0;
            box-sizing: border-box;
            border-radius: 24px;
            margin-top: 8px;
            flex-wrap: wrap;
            min-width: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 90%;
            }
            button {
              transform: rotate(45deg) scale(1.5);
            }
          }
        }
        .agreementWrapper {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          label {
            font-size: 12px;
            color: #7c7c7c;
            cursor: pointer;
          }
          input {
            width: fit-content;
            margin-top: 0;
            margin-left: 8px;
          }
        }
        button.send {
          display: block;
          margin: 48px auto 0;
          font-size: 24px;
          font-weight: 900;
          padding: 8px 72px;
          color: #fff;
          border-radius: 24px;
          background-color: #000;
        }
      }
    }
  }
}

// .MuiPopover-root {
//   .MuiPopover-paper {
//     border-radius: 0;
//     ul {
//       padding: 0;
//       li {
//         &.MuiListItem-root.Mui-selected {
//           background-image: linear-gradient(
//             90deg,
//             rgba(0, 185, 239, 1) 0%,
//             rgba(255, 69, 210, 1) 100%
//           );
//           color: #fff;
//         }
//       }
//     }
//   }
// }

.privacyPolitic {
  z-index: 99999 !important;
  .MuiDialog-scrollPaper {
    align-items: flex-start;
    margin-top: 24px;
    .MuiPaper-root {
      max-width: none;
      max-height: none;
      margin: 0;
      border-radius: 0;
      .MuiDialogContent-root {
        width: 1280px;
        height: 900px;
        padding: 32px;
        box-sizing: border-box;
        button {
          position: absolute;
          top: 32px;
          right: 32px;
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            &.mobile {
              display: none;
            }
          }
        }
        h4 {
          font-size: 20px;
        }
        p {
          font-size: 16px;
          margin-top: 16px;
        }
      }
    }
    @media screen and(max-width: 1280px) and (min-width: 641px) {
      transform: scale(0.6);
      transform-origin: top;
    }
    @media screen and (max-width: 640px) {
      margin-top: 0;
      .MuiPaper-root {
        max-height: calc(100% - 0.1px);
        .MuiDialogContent-root {
          width: 100%;
          height: 100%;
          white-space: wrap;
          button {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
