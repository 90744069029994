.scrolling {
  .contentSection {
    max-width: 1280px;
    margin: 180px auto 0;
    position: relative;
    .content {
      position: relative;
      width: fit-content;
      height: calc(var(--vh, 1vh) * 100);
      display: flex;
      align-items: center;
      .imgWrapper {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .description {
        position: absolute;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        .title {
          font-size: 60px;
          color: $onlineLiveColor;
          font-weight: 900;
        }
        .subTitle {
          font-size: 20px;
          color: $onlineLiveColor;
          font-weight: 900;
          margin: 30px 0;
        }
      }
    }
    @media screen and(max-width: 1280px) and (min-width: 641px) {
      .content {
        transform-origin: top;
        transform: scale(0.7);
      }
    }
    @media screen and (max-width: 640px) {
      max-width: none;
      width: calc(100% - 40px);
      margin: 0 auto;
      padding-top: 48px;
      box-sizing: border-box;
      .content {
        align-items: flex-start;
        .description {
          font-size: 12px;
          .title {
            font-size: 30px;
          }
          .subTitle {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.onlineLiveWrapper {
  .contentSection {
    .content {
      .description {
        color: #fff;
        .title {
          color: $onlineLiveColor;
        }
        .subTitle {
          color: $onlineLiveColor;
        }
      }
      &.content_1 {
        margin-left: 160px;
        .imgWrapper {
          width: 800px;
          opacity: 0;
          a {
            display: block;
            padding-top: 36px;
            text-decoration: none;
            font-weight: 900;
            font-size: 22px;
            color: #fff;
            img {
              margin-left: 12px;
              width: 20px;
            }
          }
        }
        .description {
          font-size: 20px;
          font-weight: 700;
          bottom: 120px;
          right: -180px;
          opacity: 0;
        }
      }
      &.content_2 {
        .imgWrapper {
          width: 760px;
          opacity: 0;
        }
        .description {
          right: -360px;
          bottom: 96px;
          opacity: 0;
        }
      }
      &.content_3 {
        margin-left: auto;
        .imgWrapper {
          width: 875px;
          opacity: 0;
        }
        .description {
          bottom: 12px;
          left: -360px;
          opacity: 0;
        }
      }
      &.content_4 {
        .imgWrapper {
          width: 850px;
          opacity: 0;
        }
        .description {
          right: -240px;
          bottom: 360px;
          opacity: 0;
        }
      }
      &.content_5 {
        margin-left: 540px;
        .imgWrapper {
          width: 500px;
          opacity: 0;
        }
        .description {
          left: -360px;
          top: 96px;
          opacity: 0;
        }
      }
      &.sixth {
        margin: 0 auto;
        flex-direction: column;
        justify-content: center;
        .description {
          position: relative;
          margin-bottom: 72px;
          flex-direction: row;
          flex-wrap: wrap;
          opacity: 0;
          width: 100%;
          .title {
            width: 100%;
            margin-bottom: 30px;
          }
          .subTitle {
            margin: 0 auto 0 0;
          }
        }
        .diagramWrapper {
          .diagram {
            opacity: 0;
            border: 1px solid #00ebff;
            width: 380px;
            padding-top: 380px;
            .diagramContent {
              color: #fff;
              margin-top: 16px;
              white-space: nowrap;
            }
            &:hover {
              border: 1px solid #fff;
              background-color: rgba(0, 235, 255, 0.65);
              .diagramTitle {
                color: #fff;
              }
            }
          }
        }
      }
      @media screen and (max-width: 640px) {
        &.content_1,
        &.content_2,
        &.content_3,
        &.content_4,
        &.content_5 {
          margin: 0;
          .imgWrapper {
            a {
              padding-top: 0;
              margin-top: 300px;
              font-size: 15px;
              text-align: right;
              img {
                width: 15px;
              }
            }
          }
        }
        &.content_1 {
          .imgWrapper {
            width: 100%;
          }
          .description {
            font-size: 15px;
            bottom: 300px;
            right: 0;
          }
        }
        &.content_2 {
          .imgWrapper {
            width: 80%;
          }
          .description {
            bottom: 300px;
            right: 0;
          }
        }
        &.content_3 {
          .imgWrapper {
            width: 90%;
          }
          .description {
            bottom: 360px;
            left: auto;
            right: 0;
          }
        }
        &.content_4 {
          .imgWrapper {
            width: 90%;
          }
          .description {
            bottom: 410px;
            right: 0;
          }
        }
        &.content_5 {
          .imgWrapper {
            width: 80%;
          }
          .description {
            top: auto;
            left: auto;
            bottom: 140px;
            right: 0;
          }
        }
        &.sixth {
          height: auto;
          padding: 72px 0 120px 0;
          .description {
            justify-content: flex-end;
            .subTitle {
              margin-bottom: 64px;
            }
          }
          .diagramWrapper {
            width: 100%;
            .diagram {
              width: 60%;
              padding-top: 60%;
              .diagramContentWrapper {
                .diagramTitle {
                  font-size: 14px;
                }
                .diagramContent {
                  font-size: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.vStudioWrapper {
  .contentSection {
    .content {
      .description {
        color: #fff;
        .title {
          color: $vStudioColor;
        }
        .subTitle {
          color: $vStudioColor;
        }
      }
      &.content_1 {
        opacity: 0;
        width: 100%;
        .imgWrapper {
          width: 100%;
          padding-top: 56.5%;
          position: relative;
          iframe,
          video {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .description {
          top: 48px;
          font-size: 20px;
        }
      }
      &.content_2 {
        margin: 0 auto;
        .imgWrapper {
          width: 980px;
          opacity: 0;
        }
        .description {
          &.part_1 {
            top: 64px;
            left: -148px;
            opacity: 0;
            .title {
              color: #fff;
            }
          }
          &.part_2 {
            bottom: 80px;
            right: -148px;
            opacity: 0;
            .subTitle {
              font-size: 32px;
              color: #fff;
              span {
                color: $vStudioColor;
              }
            }
          }
          &.part_3 {
            bottom: 80px;
            left: -128px;
            opacity: 0;
            a {
              display: block;
              padding-top: 36px;
              text-decoration: none;
              font-weight: 900;
              font-size: 22px;
              color: #fff;
              img {
                margin-left: 12px;
                width: 20px;
              }
            }
          }
        }
      }
      &.content_3 {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 300px;
        .description {
          position: sticky;
          top: 300px;
          left: 0px;
          opacity: 0;
          .title {
          }
          font-size: 20px;
          font-weight: 700;
        }
        .imgWrapper {
          width: 615px;
          position: relative;
          top: 0px;
          right: 0;
          margin-left: auto;
          .imgContent {
            img {
              opacity: 0;
            }
            &:nth-of-type(n + 2) {
              margin-top: 480px;
            }
            .title {
              margin-top: 72px;
              font-size: 32px;
              font-weight: 900;
              line-height: 1.75;
              opacity: 0;
            }
            .subTitle {
              margin-top: 32px;
              color: #fff;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.75;
              opacity: 0;
            }
          }
        }
      }
      &.content_4 {
        margin-top: 480px;
        padding-bottom: 240px;
        width: 100%;
        height: auto;
        position: relative;
        .description {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: 100;
          .title {
            color: #fff;
          }
        }
        .imgWrapper {
          width: 80%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          .imgContent {
            width: 100%;
            margin: 108px 0 48px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            img {
              width: 615px;
              opacity: 0;
            }
            .title {
              font-size: 34px;
              font-weight: 900;
              opacity: 0;
            }
            .subTitle {
              margin-top: 30px;
              color: #fff;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.75;
              opacity: 0;
            }
          }
        }
      }
    }
    @media screen and(max-width: 1280px) and (min-width: 641px) {
      height: 4700px;
      .content {
        height: auto;
        &.content_1 {
          .description {
            top: -72px;
          }
        }
        &.content_2 {
          .description.part_3 {
            bottom: -36px;
            left: 0;
          }
        }
        &.content_3 {
          padding-top: 0;
          .description {
            top: 480px;
          }
          .imgWrapper {
            .imgContent {
              &:nth-of-type(n + 2) {
                margin-top: 240px;
              }
            }
          }
        }
        &.content_4 {
          margin-top: -480px;
          padding-bottom: 0;
          .imgWrapper {
            .imgContent {
            }
          }
        }
      }
    }
    @media screen and (max-width: 640px) {
      .content {
        &.content_1 {
          height: 640px;
          .imgWrapper {
            width: 100vw;
          }
          .description {
            font-size: 15px;
            top: -48px;
          }
        }
        &.content_2 {
          height: 640px;
          .imgWrapper {
            width: 100%;
          }
          .description {
            &.part_1 {
              left: 0;
              top: -56px;
            }
            &.part_2 {
              bottom: 360px;
              right: 0;
              .subTitle {
                font-size: 15px;
              }
            }
            &.part_3 {
              bottom: 300px;
              left: auto;
              right: 0;
              a {
                font-size: 15px;
                text-align: right;
                img {
                  width: 15px;
                }
              }
            }
          }
        }
        &.content_3 {
          padding-top: 0;
          flex-direction: column;
          .description {
            font-size: 12px;
            position: relative;
            top: 0;
          }
          .imgWrapper {
            margin-top: 48px;
            width: 100%;
            .imgContent {
              &:nth-of-type(n + 2) {
                margin-top: 120px;
              }
              .title {
                margin-top: 20px;
                font-size: 15px;
              }
              .subTitle {
                margin-top: 15px;
                color: #fff;
                font-size: 12px;
              }
            }
          }
        }
        &.content_4 {
          margin-top: 240px;
          padding-bottom: 120px;
          .description {
          }
          .imgWrapper {
            margin: 0 0 0 auto;
            .imgContent {
              flex-direction: column;
              margin: 0;
              justify-content: flex-start;
              align-items: flex-end;
              &:nth-of-type(1) {
                margin-top: 96px;
              }
              &:nth-of-type(even) {
                flex-direction: column-reverse;
              }
              img {
                width: 100%;
              }
              &:nth-of-type(n + 2) {
                margin-top: 120px;
              }
              & > div {
                width: 100%;
                word-break: keep-all;
                br {
                  display: none;
                }
                .title {
                  margin-top: 20px;
                  font-size: 15px;
                }
                .subTitle {
                  margin-top: 15px;
                  color: #fff;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.itSolutionWrapper {
  .contentSection {
    .content {
      .description {
        color: #000;
        .title {
          color: $itSolutionColor;
          margin-bottom: 30px;
        }
        .subTitle {
          color: $itSolutionColor;
        }
      }
      &.content_1 {
        width: 100%;
        .description {
          position: relative;
          width: 100%;
          .subTitle {
            color: #000;
            font-weight: 700;
            font-size: 30px;
            margin-left: auto;
          }
        }
      }
      &.content_2 {
        .imgWrapper {
          width: 810px;
          opacity: 0;
        }
        .description {
          right: -400px;
          top: 40px;
          opacity: 0;
        }
      }
      &.content_3 {
        width: 100%;
        .imgWrapper {
          margin-left: auto;
          width: 810px;
          opacity: 0;
        }
        .description {
          top: 80px;
          opacity: 0;
          left: 136px;
        }
      }
    }
    @media screen and (max-width: 640px) {
      .content {
        &.content_1 {
          height: 480px;
          .description {
            .title {
              font-size: 24px;
            }
            .subTitle {
              font-size: 15px;
              margin-left: 0;
            }
          }
        }
        &.content_2,
        &.content_3 {
          height: 640px;
          .imgWrapper {
            margin-left: 0;
            width: 80%;
          }
          .description {
            right: 0;
            top: auto;
            font-weight: 700;
          }
        }
        &.content_2 {
          .description {
            bottom: 170px;
          }
        }
        &.content_3 {
          .description {
            bottom: 150px;
          }
        }
      }
    }
  }
}
