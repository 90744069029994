.adminNAv {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 36px;
  position: fixed;
  z-index: 10;
  background-color: #000;
  display: flex;
  align-items: center;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.45);
  a {
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    &:nth-of-type(n + 2)::before {
      content: '|';
      margin: 24px;
    }
  }
}
.popupSettingWrapper {
  width: 1280px;
  padding-top: 180px;
  margin: 0 auto;
  .popupListWrapper {
    display: flex;
    flex-wrap: wrap;
    .singlePopup {
      width: 300px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-radius: 4px;
      box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
      }
      .info {
        display: flex;
        align-items: center;
        padding: 4px;
        box-sizing: border-box;
        label {
          flex: 0 0 auto;
          font-size: 12px;
          width: 48px;
        }
        span {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 244px;
        }
      }
      button {
        margin-top: auto;
        width: 100%;
        padding: 8px 0;
        font-size: 16px;
        font-weight: 700;
        background-color: #000;
        color: #fff;
      }
    }
  }
  nav {
    margin-top: 36px;
    ul.MuiPagination-ul {
      width: fit-content;
      margin: 0 auto;
    }
  }
  .addPopup {
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: 72px;
    height: 72px;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: all 0.3s ease;
    z-index: 100;
    &:hover {
      transform: translate(0, -8px);
    }
  }
}
