.popupSettingModalWrapper {
  width: 360px;
  .inputWrapper {
    display: flex;
    align-items: center;
    &:nth-of-type(n + 2) {
      margin-top: 12px;
    }
    label {
      width: 72px;
      flex: 0 0 auto;
      font-size: 14px;
    }
    div.endDate {
      width: 240px;
      min-height: 28px;
      border-bottom: 1px solid #5c5c5c;
    }
    input.hiddenImgSelect {
      display: none;
    }
    label.addImageBtn,
    button.reset,
    button.endDate {
      font-size: 12px;
      padding: 2px 4px;
      border: 1px solid #5c5c5c;
      border-radius: 8px;
    }
    label.addImageBtn {
      margin-left: auto;
      width: auto;
    }
    button.reset {
      margin-left: auto;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    button.endDate {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  img {
    margin-top: 4px;
    border: 1px solid #5c5c5c;
    display: block;
    width: 100%;
  }
  button.regist {
    margin-top: 12px;
    background-color: #000;
  }
  button.delete {
    margin-top: 4px;
    background-color: #c5c5c5;
  }
  button.regist,
  button.delete {
    font-size: 20px;
    font-weight: 700;
    border-radius: 20px;
    color: #fff;
    width: 100%;
    padding: 4px 0;
  }
}

.popupBgWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  .popupWrapper {
    width: 300px;
    position: relative;
    .slick-slider {
      .slick-list {
        .slick-track {
          height: 300px;
          .slick-slide {
            & > div {
              width: 100%;
              height: 100%;
              .imgWrapper {
                width: 100%;
                height: 100%;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  object-position: center;
                }
              }
            }
          }
        }
      }
      ul.slick-dots {
        bottom: 0;
      }
    }
    .uiWrapper {
      display: flex;
      justify-content: space-between;
      button {
        opacity: 0.75;
        font-weight: 500;
      }
    }
  }
}
