.peopleWrapper {
  color: #fff;
  .peopleHeadSectionWrapper {
    width: 100%;
    height: calc((var(--vh, 1vh) * 100) - 200px);
    background-image: url('./img/poeple_bg.jpg');
    background-size: cover;
    background-position: bottom;
    .headSection {
      box-sizing: border-box;
      max-width: 1280px;
      width: fill-available;
      margin: 0 auto;
      padding: 180px 0 120px;
      color: #fff;
      .subTitle {
        margin-top: 72px;
        line-height: 1.5;
      }
    }
  }
  .peopleSecondSectionWrapper {
    background-color: #ff4929;
    .secondSection {
      max-width: 1280px;
      margin: 0 auto;
      padding: 240px 0 120px;
      position: relative;
      .titleOut {
        font-size: 60px;
        font-weight: 900;
        position: absolute;
        top: -52px;
        right: 0;
        opacity: 0;
      }
      .description {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        font-size: 20px;
        line-height: 1.75;
        font-weight: 500;
        margin-bottom: 72px;
        opacity: 0;
        .title {
          font-size: 60px;
          font-weight: 900;
          width: 100%;
          margin-bottom: 30px;
        }
        .subTitle {
          font-weight: 900;
          font-size: 34px;
          margin-right: auto;
        }
      }
      .diagramWrapper {
        .diagram {
          opacity: 0;
          border: 1px solid #fff;
          width: 420px;
          padding-top: 420px;
          @media screen and(max-width: 640px) {
            width: 60%;
            padding-top: 60%;
          }
          .diagramContentWrapper {
            .diagramNumber {
              font-size: 20px;
              @media screen and(max-width: 640px) {
                font-size: 10px;
              }
            }
            .diagramTitle {
              font-size: 34px;
              margin: 12px 0 48px;
              @media screen and(max-width: 640px) {
                font-size: 15px;
                margin: 6px 0 16px;
              }
            }
            .diagramContent {
              font-size: 14px;
              line-height: 1.75;
              @media screen and(max-width: 640px) {
                font-size: 12px;
                width: max-content;
              }
            }
          }
        }
      }
    }
  }
  .peopleThirdSectionWrapper {
    background-color: #fff;
    position: relative;
    &::after,
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 560px;
    }
    &::before {
      background-image: url('./img/people_footer_3.jpg');
      background-size: cover;
      background-position: top;
    }
    &::after {
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 10;
    }
    .thirdSection {
      z-index: 100;
      max-width: 1280px;
      margin: 0 auto;
      padding: 120px 0 120px;
      position: relative;
      color: #000;
      & > div {
        margin-bottom: 172px;
      }
      .title {
        font-size: 60px;
        font-weight: 900;
      }
      .description {
        font-size: 30px;
        font-weight: 700;
        width: fit-content;
        margin-left: auto;
      }
      .whatWeDo {
        .headerTitle {
          opacity: 0;
        }
        .jobWrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: stretch;
          padding: 0 64px;
          .job {
            width: 33.3%;
            flex: 0 0 auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 72px;
            opacity: 0;
            position: relative;
            .title {
              font-size: 20px;
              font-weight: 900;
              line-height: 1.75;
            }
            .imgWrapper {
              margin: 36px 0;
              width: 180px;
              height: 180px;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .description {
              margin-left: 0;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
      .ourWelfareWrapper {
        opacity: 0;
      }
      .welfareWrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 124px;
        .welfare {
          opacity: 0;
          color: #fff;
          .title {
            font-size: 40px;
            font-weight: 900;
          }
          .description {
            margin-top: 48px;
            font-size: 20px;
            line-height: 1.75;
            font-weight: 500;
          }
        }
      }
    }
  }
  @media screen and(max-width: 1280px) and (min-width: 641px) {
    .peopleHeadSectionWrapper {
      height: calc((var(--vh, 1vh) * 100) - 120px);
      .headSection {
        transform: scale(0.7);
        transform-origin: top;
      }
    }
    .peopleSecondSectionWrapper {
      height: 800px;
      .secondSection {
        transform: scale(0.7);
        transform-origin: top;
      }
    }
    .peopleThirdSectionWrapper {
      height: 1500px;
      &::before,
      &::after {
        bottom: -240px;
      }
      .thirdSection {
        transform: scale(0.7);
        transform-origin: top;
      }
    }
  }
  @media screen and (max-width: 640px) {
    .peopleHeadSectionWrapper {
      height: calc((var(--vh, 1vh) * 100) - 280px);
      .headSection {
        max-width: none;
        width: calc(100% - 40px);
        height: auto;
        padding-top: 124px;
        display: block;
        .subTitle {
          margin-top: 30px;
          font-size: 15px;
        }
      }
    }
    .peopleSecondSectionWrapper {
      .secondSection {
        max-width: none;
        width: calc(100% - 40px);
        height: auto;
        padding-top: 300px;
        .titleOut {
          font-size: 30px;
          top: -18px;
          left: 20px;
        }
        .description {
          font-size: 12px;
          flex-direction: column;
          .title {
            font-size: 30px;
            top: -18px;
          }
          .subTitle {
            font-size: 15px;
            margin: 0 auto 15px 0;
          }
        }
      }
    }
    .peopleThirdSectionWrapper {
      &::after,
      &::before {
        height: 740px;
      }
      .thirdSection {
        max-width: none;
        width: calc(100% - 40px);
        height: auto;
        & > div {
          margin-bottom: 96px;
        }
        .title {
          font-size: 30px;
        }
        .description {
          font-size: 15px;
          margin-left: 0;
          margin-top: 30px;
        }
        .whatWeDo {
          .jobWrapper {
            flex-direction: column;
            padding: 0;
            .job {
              width: 100%;
              margin-top: 0;
              &::after {
                content: '';
                width: calc(100% + 40px);
                height: 100%;
                position: absolute;
                top: 0;
                left: -20px;
                background-color: rgba(255, 73, 41, 0.7);
                opacity: 0;
              }
              &:hover {
                &::after {
                  opacity: 1;
                }
                .description {
                  opacity: 1;
                }
              }
              .title {
                font-size: 15px;
                z-index: 10;
                position: absolute;
                top: 10px;
                left: 0;
                color: #fff;
                text-align: left;
              }
              .imgWrapper {
                width: calc(100% + 40px);
                margin: 0;
                border-radius: 0;
              }
              .description {
                opacity: 0;
                font-size: 12px;
                font-weight: 700;
                z-index: 10;
                position: absolute;
                bottom: 36px;
                text-align: left;
                color: #fff;
                width: 100%;
                br {
                  display: none;
                }
              }
            }
          }
        }
        .welfareWrapper {
          flex-direction: column;
          margin-top: 48px;
          .welfare {
            .title {
              font-size: 24px;
            }
            .description {
              font-size: 15px;
              margin-top: 20px;
            }
            &:nth-of-type(n + 2) {
              margin-top: 64px;
            }
          }
        }
      }
    }
  }
}
